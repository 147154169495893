import React from 'react';
import PayPalButton from './PayPalButton';

const PaypalPaymentPage = () => {
  const amount = "1.00"; // Example amount to pay

  return (
    <div>
      <h2>Pay with PayPal</h2>
      <PayPalButton amount={amount} />
    </div>
  );
};

export default PaypalPaymentPage;
