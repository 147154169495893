import React, { useEffect, useRef, useState } from "react";
import MetaData from "../layouts/MetaData";
import { clearAuthError, login } from "../../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import {
  getServices,
  getServicesOfSelectedVehicle,
} from "../../actions/serviceActions";
// import Select from "react-dropdown-select";
import Select from "react-select";
import PickupDynamicForm from "./PickupDynamicForm";
import DropoffDynamicForm from "./DropoffDynamicForm";

import { createOrder } from "../../actions/orderActions";
import { clearOrderCreated } from "../../slices/ordersSlice";
import { clearOrderError } from "../../slices/ordersSlice";

import { modifyTotalPriceAction } from "../../actions/totalPriceAction";
import { getSubServices } from "../../actions/subServiceActions";
import { getHourlyData } from "../../actions/hourlyDataActions";
import PaymentPaypal from "../orders/paypal/PaymentPaypal.js";

export default function BookingForm() {
  const selectInputRef = useRef();
  const triggerAfterVehicleChangePURef = useRef();
  const triggerAfterVehicleChangeDORef = useRef();

  const [totalPriceFinal, setTotalPriceFinal] = useState(0);

  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState("10:00");
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [selectedService, setSelectedService] = useState({
    serviceCategory: "None",
  });
  const [selectedSubService, setSelectedSubService] = useState({
    subServiceCategory: "None",
  });

  const [filteredServices, setfilteredServices] = useState(null);
  const [filteredSubServices, setfilteredSubServices] = useState([
    {
      subServiceCategory: "NONE",
    },
  ]);
  const [serviceStatus, setServiceStatus] = useState(false);
  const [serviceTypeStatus, setServiceTypeStatus] = useState(false);
  const [hoursStatus, setHoursStatus] = useState(false);
  const [cruiseTerminalsStatus, setCruiseTerminalsStatus] = useState(false);
  const [terminalStatus, setTerminalStatus] = useState(false);
  const [terminal, setTerminal] = useState(null);
  const [cruiseTerminal, setCruiseTerminal] = useState("");
  const [submitBtnDisableState, setSubmitBtnDisableState] = useState(true);

  // const [selectedSubServices, setselectedSubServices] = useState(null);

  const [pickupAddress, setPickupAddress] = useState("");
  const [dropoffAddress, setDropoffAddress] = useState("");
  const [noOfPax, setNoOfPax] = useState(1);
  const [noOfLuggage, setNoOfLuggage] = useState(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("+65");
  const [message, setMessage] = useState("");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [wayTransferPrice, setWayTransferPrice] = useState("");
  const [selectedHour, setSelectedHour] = useState(null);
  const [termAndCondText, setTermAndCondText] = useState("");
  const [additionalPickupAddressData, setAdditionalPickupAddressData] =
    useState({
      additionalPickupAddress: [],
      additionalPickupCharges: 0,
    });
  const [additionalDropOffAddressData, setAdditionalDropOffAddressData] =
    useState({
      additionalDropOffAddress: [],
      additionalDropOffCharges: 0,
    });
  const [additionalStopPrice, setAdditionalStopPrice] = useState(10);

  const [selectedHourlyData, setSelectedHourlyData] = useState([]);
  const nullValueService = [
    {
      title: "Please select a service",
      price: 0,
      label: "Please select a service",
      desc: "1.waiting charges 2.Additional stop chargesLorem Ipsum is simply dummy text of the printing",
      vehicleId: "6730507291358186a7547737",
      vehicleCategory: "6_SEATER",
      serviceCode: "NONE",
      serviceCategory: "NONE",
      images: [
        {
          image: "airport_dep.png",
        },
      ],
    },
  ];
  const cruiseTerminals = [
    {
      _id: "1",
      value: "Marina Bay Cruise Centre",
      label: "Marina Bay Cruise Centre",
    },
    {
      _id: "2",
      value: "Harbour Front Ferry Terminal",
      label: "Harbour Front Ferry Terminal",
    },
    {
      _id: "3",
      value: "Tanah Merah Ferry Terminal",
      label: "Tanah Merah Ferry Terminal",
    },
    {
      _id: "4",
      value: "Changi Point  Ferry Terminal",
      label: "Changi Point  Ferry Terminal",
    },
  ];

  const terminalsData = [
    {
      _id: "1",
      value: "Terminal-1",
      name: "Terminal-1",
      label: "Terminal-1",
    },
    {
      _id: "2",
      value: "Terminal-2",
      name: "Terminal-2",
      label: "Terminal-2",
    },
    {
      _id: "3",
      value: "Terminal-3",
      name: "Terminal-3",
      label: "Terminal-3",
    },
    {
      _id: "4",
      value: "Terminal-4",
      name: "Terminal-4",
      label: "Terminal-4",
    },
    {
      _id: "5",
      value: "Jewel Changi Airport",
      name: "Jewel Changi Airport",
      label: "Jewel Changi Airport",
    },
    {
      _id: "6",
      value: "Seletar Airport",
      name: "Seletar Airport",
      label: "Seletar Airport",
    },
  ];

  const paymentMethods = [
    {
      _id: "1",
      value: "CREDIT_CARD_PAYMENT",
      label: "CARD PAYMENT - 10% Service Charges Apply",
    },
    {
      _id: "2",
      value: "PAYNOW_QR",
      label: "PAYNOW QR CODE",
    },
    {
      _id: "3",
      value: "CASH_PAYMENT",
      label: "CASH PAYMENT",
    },
  ];

  const dispatch = useDispatch();
  // const { loading, error, isAuthenticated, user } = useSelector(
  //   (state) => state.authState
  // );
  const { services, servicesForSelectedVehicle } = useSelector(
    (state) => state.serviceState
  );

  const { hourlyData } = useSelector((state) => state.hourlyDataState);

  const { subServices, subServicesForSelectedService } = useSelector(
    (state) => state.subServiceState
  );

  const { priceDetails, totalPrice } = useSelector(
    (state) => state.totalPriceState
  );

  const { isOrderCreated, order, error } = useSelector(
    (state) => state.orderState
  );

  const { vehicles } = useSelector((state) => state.vehicleState);
  let fullPriceDetails = useSelector((state) => state.totalPriceState);

  const [midnightSurcharge, setMidnightSurcharge] = useState(false);
  const [tuasSouthBoulevard, setTuasSouthBoulevard] = useState(false);
  const [additionalStopAlongtheWay, setAdditionalStopAlongtheWay] =
    useState(false);
  const [additionalStopOutoftheWay, setAdditionalStopOutoftheWay] =
    useState(false);

  const navigate = useNavigate();

  const submitHandler = (e) => {
    e.preventDefault();

    let errMsg = [];
    if (!selectedVehicle) {
      errMsg.push("select the vehicle");
    }
    if (!selectedService) {
      errMsg.push("select the service");
    }
    if (pickupAddress.trim() === "") {
      errMsg.push("enter the pickup address");
    }
    if (dropoffAddress.trim() === "") {
      errMsg.push("enter the dropoff address");
    }

    if (noOfPax === 0) {
      errMsg.push("enter no of pax");
    }
    if (name === "") {
      errMsg.push("enter the Name");
    }
    if (email === "") {
      errMsg.push("enter the Email");
    }
    if (phoneNumber === "") {
      errMsg.push("enter the phone number");
    }
    if (!selectedPaymentMethod) {
      errMsg.push("select the payment method");
    }

    if (selectedSubService.subServiceCategory === "None") {
      if (selectedService.serviceCategory === "AIRPORT_TRANSFER") {
        errMsg.push("please select the Airport service");
      }
      if (selectedService.serviceCategory === "CRUISE_TRANSFER") {
        errMsg.push("please select the Cruise Terminal");
      }
    }

    console.log("selectedService : ", selectedService);
    console.log("selectedSubService : ", selectedSubService);

    if (errMsg.length > 0) {
      toast(`Please ${errMsg.join(", ")}`, {
        position: "bottom-center",
        type: "error",
      });
      return;
    }

    document.getElementById("submitBtn").disabled = true;
    console.log(
      "Modify price state:  priceDetails",
      priceDetails,
      "Total price: ",
      totalPrice
    );
    let bookingData = {
      category: selectedVehicle.category,
      serviceName: selectedService.title,
      serviceSubName: selectedSubService.title,

      vehicleName: selectedVehicle.name,
      rate: {
        serviceAmount: selectedSubService.price,
        fullAmount: totalPrice,
      },

      bookingPlatform: "WEB",
      customerDetails: {
        name: name,
        email: email,
        phone: phoneNumber,

        message: message,
      },

      pickupDate: date,
      pickupTime: time,

      pickupAddress: pickupAddress,
      dropoffAddress: dropoffAddress,

      noOfPax: noOfPax,
      noOfLuggage: noOfLuggage,

      additionalPickupAddress:
        additionalPickupAddressData.additionalPickupAddress,

      additionalPickupCharges:
        additionalPickupAddressData.additionalPickupCharges,

      additionalDropOffAddress:
        additionalDropOffAddressData.additionalDropOffAddress,

      additionalDropOffCharges:
        additionalDropOffAddressData.additionalDropOffCharges,

      additionalCharges: {
        midnightSurcharge: {
          status: midnightSurcharge,
        },
        tuasSouthBoulevard: {
          status: tuasSouthBoulevard,
        },
        additionalStopAlongtheWay: {
          status: additionalStopAlongtheWay,
        },
        additionalStopOutoftheWay: {
          status: additionalStopOutoftheWay,
        },
      },
      paymentDetails: {
        paymentMode: selectedPaymentMethod.value,
        paymentStatus: "PENDING",
      },

      orderStatus: "PENDING",
      termsAndConditions: selectedSubService.desc,

      // user: user._id,
      vehicleId: selectedVehicle._id,
      serviceId: selectedService._id,
      subServiceId: selectedSubService._id,
    };

    if (
      selectedSubService.subServiceCategory === "AIRPORT_DEP" ||
      selectedSubService.subServiceCategory === "AIRPORT_DEP_ARR"
    ) {
      bookingData.dropoffAddress = bookingData.pickupAddress + " " + terminal;
    }
    if (selectedSubService.subServiceCategory === "AIRPORT_ARR") {
      bookingData.pickupAddress = bookingData.dropoffAddress + " " + terminal;
    }

    // if(selectedSubService[0].subServiceCategory === 'CRUISE_DEP' ) {
    //   bookingData.dropoffAddress = bookingData.pickupAddress + ' ' + cruiseTerminal
    // }
    // if(selectedSubService[0].subServiceCategory === 'AIRPORT_ARR') {
    //   bookingData.pickupAddress = bookingData.dropoffAddress + ' ' + cruiseTerminal
    // }
    console.log("bookingData ::: ", bookingData);
    sessionStorage.setItem("bookingData", JSON.stringify(bookingData));

    if (selectedPaymentMethod.value === "CASH_PAYMENT") {
      dispatch(createOrder(bookingData));
    } else if (selectedPaymentMethod.value === "PAYNOW_QR") {
      // navigate("/paymentqr");
      navigate("/hitpay-payment-paynow-qr");
    } else {
      // navigate("/payment");
      navigate("/hitpay-payment-paynow-qr");
    }
  };

  useEffect(() => {
    // if (!isAuthenticated) {
    //   navigate("/login");
    // }

    // if(filteredServices !== null) {
    //   serviceSelectHandler(nullValueService)
    //   return
    // }

    if (error) {
      toast(error, {
        position: "bottom-center",
        type: "error",
        onOpen: () => {
          dispatch(clearOrderError);
          document.getElementById("submitBtn").disabled = false;
        },
      });
      return;
    }

    if (isOrderCreated) {
      toast("Booking successful , You will receive email soon", {
        position: "bottom-center",
        type: "success",
        onOpen: () => {
          setTimeout(() => {
            document.getElementById("submitBtn").disabled = false;
          }, "5000");
          dispatch(clearOrderCreated());
        },
      });
      return;
    }

    if (services.length === 0) {
      dispatch(getServices);
    }
    if (subServices.length === 0) {
      dispatch(getSubServices);
    }
    if (hourlyData.length === 0) {
      dispatch(getHourlyData);
    }
  }, [
    error,
    // isAuthenticated,
    dispatch,
    navigate,
    // selectedVehicle,
    // filteredServices,
    isOrderCreated,
    // totalPrice,
    // totalPriceFinal,
  ]);

  const calltriggerAfterVehicleChangePU = () => {
    if (triggerAfterVehicleChangePURef.current) {
      triggerAfterVehicleChangePURef.current.triggerAfterVehicleChangePU(); // Call the child function
    }
  };

  const calltriggerAfterVehicleChangeDO = () => {
    if (triggerAfterVehicleChangeDORef.current) {
      triggerAfterVehicleChangeDORef.current.triggerAfterVehicleChangeDO(); // Call the child function
    }
  };

  const vehicleSelectHandler = (vehicle) => {
    console.log("vehicle : ", vehicle);

    handleMidnightSurcharge(vehicle, time, null);

    if (
      vehicle.category === "40/45_SEATER" ||
      vehicle.category === "23_SEATER"
    ) {
      setAdditionalStopPrice(20);
    } else {
      setAdditionalStopPrice(10);
    }

    setSelectedVehicle(vehicle);

    setTermAndCondText(vehicle.desc);

    console.log("vehicle.desc :", vehicle.desc);

    //CLEAR EVERYTHING

    //clear service type
    setfilteredServices(null);
    setSelectedService(null);

    //clear sub-service
    setfilteredSubServices(null);
    setSelectedSubService(null);
    setServiceStatus(false);

    //clear way transfer price
    setWayTransferPrice("");

    //clear airport terminal
    setTerminal(null);
    setTerminalStatus(false);
    //... General terminal data was reset when vehicle changes

    //clear cruise terminal
    setCruiseTerminal(null);
    setCruiseTerminalsStatus(false);

    //clear hours
    setSelectedHour(null);
    setHoursStatus(false);

    // setSelectedSubService(
    //   {
    //     subServiceCategory: "None",
    //   },
    // );
    // setSelectedService(
    //   {
    //     serviceCategory: "None",
    //   },
    // );

    const res = services.filter((service) => {
      // console.log(`${service.vehicleCategory} === ${vehicle.category}`)
      return service.vehicleCategory === vehicle.category;
    });

    setfilteredServices(res);
    console.log("vehicles ---- ", res);

    if (vehicle.category === "23_SEATER") {
      const filteredHrData = hourlyData.filter(
        (hrdata) => hrdata.hourlyDataCategory === "23_SEATER"
      );
      setSelectedHourlyData(filteredHrData[0].data);
    } else if (vehicle.category === "40/45_SEATER") {
      const filteredHrData = hourlyData.filter(
        (hrdata) => hrdata.hourlyDataCategory === "40/45_SEATER"
      );
      setSelectedHourlyData(filteredHrData[0].data);
    } else if (vehicle.category === "6_7_SEATER") {
      const filteredHrData = hourlyData.filter(
        (hrdata) => hrdata.hourlyDataCategory === "6_7_SEATER"
      );
      setSelectedHourlyData(filteredHrData[0].data);
      console.log("filteredHrData :", filteredHrData[0].data);
    } else {
      const filteredHrData = hourlyData.filter(
        (hrdata) => hrdata.hourlyDataCategory === "9_13_SEATER"
      );
      setSelectedHourlyData(filteredHrData[0].data);
      console.log("filteredHrData :", filteredHrData[0].data);
    }

    calltriggerAfterVehicleChangePU();
    calltriggerAfterVehicleChangeDO();
  };

  //SERVICE HANDLER
  const serviceSelectHandler = (service) => {
    //CLEAR EVERYTHING AFTER SERVICE

    //clear sub-service
    setfilteredSubServices(null);
    setSelectedSubService(null);

    //clear way transfer price
    setWayTransferPrice("");

    //clear airport terminal
    setTerminal(null);
    setTerminalStatus(false);
    //... General terminal data was reset when vehicle changes

    //clear cruise terminal
    setCruiseTerminal(null);
    setCruiseTerminalsStatus(false);

    //clear hours
    // setSelectedHour(null)
    // setHoursStatus(false)

    console.log("service format :", service);

    setSelectedService(service);

    console.log("Service  : ", service);

    const res = subServices.filter(
      (subService) => subService.serviceCode === service.serviceCode
    );

    console.log("filtered sub Services  : ", res);

    //AIRPORT & CRUISE
    if (
      service.serviceCategory === "AIRPORT_TRANSFER" ||
      service.serviceCategory === "CRUISE_TRANSFER"
    ) {
      setWayTransferPrice("");
    }
    if (service.serviceCategory === "CRUISE_TRANSFER") {
      setCruiseTerminalsStatus(true);
    }

    //ONE &TWO WAY - CITY

    if (
      service.serviceCategory === "ONE_WAY_TRANSFER" ||
      service.serviceCategory === "TWO_WAY_TRANSFER" ||
      service.serviceCategory === "CITY_TRANSFER"
    ) {
      console.log("Inside one way");

      setServiceStatus(false);
      setHoursStatus(false);

      setWayTransferPrice(`Price : $${res[0].price.toFixed(2)}`);

      console.log(`Price : $${res[0].price.toFixed(2)}`);

      subServiceSelectHandler(res[0]);
      // setfilteredSubServices(null)
      return;
    }

    //HOURLY-BOOKING

    if (service.serviceCategory === "HOURLY_BOOKING") {
      console.log("inside the hourly");

      setHoursStatus(true);
      setServiceStatus(false);

      // if (
      //   service.vehicleCategory === "6_SEATER" ||
      //   "7_SEATER" ||
      //   "9_SEATER" ||
      //   "13_SEATER"
      // ) {
      //   const res1 = subServices.filter(
      //     (subService) => subService.subServiceCategory === "HOURLY_BOOKING"
      //   );

      // }
      console.log("selected service type : ", res[0]);
      subServiceSelectHandler(res[0]);

      return;
    }

    if (service.serviceCategory === "AIRPORT_TRANSFER") {
      setfilteredSubServices(res);

      setServiceStatus(true);
      setHoursStatus(false);
    }

    if (service.serviceCategory === "CRUISE_TRANSFER") {
      setfilteredSubServices(res);

      setServiceStatus(true);
      setHoursStatus(false);
    }
  };

  const subServiceSelectHandler = async (subService) => {
    //CLEAR EVERYTHING AFTER SUB-SERVICE

    //clear way transfer price
    // setWayTransferPrice("");

    //clear airport terminal
    setTerminal(null);
    setTerminalStatus(false);
    //... General terminal data was reset when vehicle changes

    //clear cruise terminal
    setCruiseTerminal(null);
    setCruiseTerminalsStatus(false);

    console.log("subservice config :", subService);
    setSelectedSubService(subService);

    if (subService.subServiceCategory === "HOURLY_BOOKING") {
      setHoursStatus(true);
    } else {
      //clear hours
      setSelectedHour(null);
      setHoursStatus(false);
    }

    if (
      subService.subServiceCategory === "AIRPORT_DEP" ||
      subService.subServiceCategory === "AIRPORT_DEP_ARR"
    ) {
      setDropoffAddress("Airport");
      setTerminalStatus(true);
    } else if (subService.subServiceCategory === "AIRPORT_ARR") {
      setPickupAddress("Airport");
      setTerminalStatus(true);
    } else {
      setTerminalStatus(false);
    }

    if (subService.subServiceCategory === "CRUISE_DEP") {
      setCruiseTerminalsStatus(true);
    } else if (subService.subServiceCategory === "CRUISE_ARR") {
      setCruiseTerminalsStatus(true);
    } else {
      setCruiseTerminalsStatus(false);
    }

    console.log("subService  : ", subService);

    let tempPriceDetails = { ...fullPriceDetails.priceDetails };
    console.log("fullPriceDetails : ", fullPriceDetails);
    console.log(
      "fullPriceDetails.priceDetails : ",
      fullPriceDetails.priceDetails
    );
    console.log("final implementation", {
      ...fullPriceDetails,
      priceDetails: { ...tempPriceDetails, servicePrice: subService.price },
    });
    console.log("sub service...");
    await dispatch(
      modifyTotalPriceAction(
        {
          ...fullPriceDetails,
          priceDetails: {
            ...fullPriceDetails.priceDetails,
            servicePrice: subService.price,
          },
        },
        fullPriceDetails.paymentMethod
      )
    );
  };

  const handleHoursData = async (hoursData) => {

    console.log(">>>>>>>>>", selectedVehicle, time, hoursData.hours)
    let midnightCharge = await handleMidnightSurcharge(selectedVehicle, time, hoursData.hours);

    console.log("Hours : ", hoursData.hours);
    setSelectedHour(hoursData);
    setSelectedSubService({
      ...selectedSubService,
      price: hoursData.value,
      title: hoursData.title,
    });


    console.log()

       dispatch(
        modifyTotalPriceAction(
          {
            ...fullPriceDetails,
            priceDetails: {
              ...fullPriceDetails.priceDetails,
              servicePrice: hoursData.value,
              midnightSurcharge: midnightCharge
            },
          },
          fullPriceDetails.paymentMethod
        )
      );


     

  };

  // const cruiseTerminalSelectHandler = (selectedCruiseTerminal) => {
  //   // setSelectedService(service);

  //   console.log("Service[0]  : ", selectedCruiseTerminal)

  //   // const res = subServices.filter(
  //   //   (subService) => subService.serviceId === service[0].value
  //   // );

  //   console.log("filtered sub Services[0]  : ", res)

  // }

  const handleCruiseTerminal = async (ct) => {
    console.log("ct :", ct);
    setCruiseTerminal(ct);
    setDropoffAddress("");
    setPickupAddress("");

    if (selectedSubService.subServiceCategory === "CRUISE_DEP") {
      setDropoffAddress(ct.value);
    }
    if (selectedSubService.subServiceCategory === "CRUISE_ARR") {
      setPickupAddress(ct.value);
    }
  };

  const handleSelectedPaymentMethod = async (paymentMethod) => {
    setSelectedPaymentMethod(paymentMethod);
    console.log("Handle select value e: ", paymentMethod.value);
    await dispatch(
      modifyTotalPriceAction({ ...fullPriceDetails }, paymentMethod.value)
    );
  };

  //ADD MORE PICKUP & DROPOFF

  const pickUpTrack = (data) => {
    console.log("pickup track ", data);

    let pickUpPrice = data.length * 10;

    if (selectedVehicle !== null) {
      console.log("step 1");
      if (
        selectedVehicle.category === "23_SEATER" ||
        selectedVehicle.category === "40/45_SEATER"
      ) {
        console.log("step 2");

        pickUpPrice = data.length * 20;
      }
    }

    console.log("pickUpPrice : ", pickUpPrice);

    setAdditionalPickupAddressData({
      additionalPickupAddress: data,
      additionalPickupCharges: pickUpPrice,
    });

    dispatch(
      modifyTotalPriceAction(
        {
          ...fullPriceDetails,
          priceDetails: {
            ...fullPriceDetails.priceDetails,
            additionalPickUpPrice: pickUpPrice,
          },
        },
        fullPriceDetails.paymentMethod
      )
    );
  };

  const dropOffTrack = async (data) => {
    console.log("dropoff track ", data);
    let dropOffPrice = data.length * 10;

    if (selectedVehicle !== null) {
      console.log("step 1");
      if (
        selectedVehicle.category === "23_SEATER" ||
        selectedVehicle.category === "40/45_SEATER"
      ) {
        console.log("step 2");

        dropOffPrice = data.length * 20;
      }
    }

    console.log("pickUpPrice : ", dropOffPrice);



    setAdditionalDropOffAddressData({
      additionalDropOffAddress: data,
      additionalDropOffCharges: dropOffPrice,
    });


    await dispatch(
      modifyTotalPriceAction(
        {
          ...fullPriceDetails,
          priceDetails: {
            ...fullPriceDetails.priceDetails,
            additionalDropOffPrice: dropOffPrice,
          },
        },
        fullPriceDetails.paymentMethod
      )
    );
  };

  const handleMidnightSurcharge = async (vehicleData, t, hours) => {
    let midnightSurchargeValue = 10;
    console.log("time : ", t);
    let hrs = Number(t[0] + t[1]);
    let mins = Number(t[3] + t[4]);

    console.log("step 1 : ", midnightSurchargeValue);

    if ((hrs >= 23 && mins >= 30) || hrs < 6 || (hrs === 6 && mins <= 30)) {
      // if(selectedVehicle){
      //   if(selectedVehicle.category === "40/45_SEATER" || selectedVehicle.category === "23_SEATER") {
      //     midnightSurchargeValue = 30
      //     console.log("step 2 : ", midnightSurchargeValue)

      //   }
      // }

      // DATA FROM
      if (vehicleData !== null) {
        if (vehicleData) {
          if (
            vehicleData.category === "40/45_SEATER" ||
            vehicleData.category === "23_SEATER"
          ) {
            midnightSurchargeValue = 30;
            console.log("step 2 : ", midnightSurchargeValue);
          }
        }
      }
    } else {
      
      if (hours !== null) {
        console.log("step 3 : ", midnightSurchargeValue);
        //add hourly booking duration + booking time in hrs
        let hrsNew = hrs + hours;
        console.log("hrsNew :", hrsNew)

        // if(hrsNew > 23){
        //   hrsNew = hrsNew - 24
        // }
        // console.log("hrsNew :", hrsNew)

        // if ((hrsNew >= 23 && mins >= 30) || hrsNew < 6 || (hrsNew === 6 && mins <= 30)) {
        //   console.log("step 4 : ", midnightSurchargeValue);

        //   if (
        //     vehicleData.category === "40/45_SEATER" ||
        //     vehicleData.category === "23_SEATER"
        //   ) {
        //     midnightSurchargeValue = 30;

        //     console.log("step 5 : ", midnightSurchargeValue);

        //   } else {
        //     midnightSurchargeValue = 10;
        //     console.log("step 6 : ", midnightSurchargeValue);
        //   }
        // }
        
        if((hrsNew === 23 && mins >=30) || hrsNew >= 24)  {
            console.log("step 4 : ", midnightSurchargeValue);

          if (
            vehicleData.category === "40/45_SEATER" ||
            vehicleData.category === "23_SEATER"
          ) {
            midnightSurchargeValue = 30;

            console.log("step 5 : ", midnightSurchargeValue);

          } else {
            midnightSurchargeValue = 10;
            console.log("step 6 : ", midnightSurchargeValue);
          }
        } else {
        console.log("hrNew less than 23:30")

          midnightSurchargeValue = 0;
            console.log("step 6a : ", midnightSurchargeValue);

        }


      } else {

        
          setMidnightSurcharge(false);
          midnightSurchargeValue = 0;
          console.log("step nil : ", midnightSurchargeValue);
        
        
      }

      
    }

    if (midnightSurchargeValue !== 0) {
      setMidnightSurcharge(true);
    }
    await dispatch(
      modifyTotalPriceAction(
        {
          ...fullPriceDetails,
          priceDetails: {
            ...fullPriceDetails.priceDetails,
            midnightSurcharge: midnightSurchargeValue,
          },
        },
        fullPriceDetails.paymentMethod
      )
    );

    return midnightSurchargeValue
  };

  return (
    <>
      <MetaData title={`Book Now`} />
      <section className="" style={{ backgroundColor: "#508bfc" }}>
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
              <div
                className="card shadow-2-strong"
                style={{ borderRadius: "1rem" }}
              >
                <div className="card-body p-5 text-center">
                  <h3 className="mb-5">Book Now</h3>
                  {vehicles && (
                    <div
                      data-mdb-input-init
                      className="form-outline mb-4 text-start"
                    >
                      <label className="form-label" htmlFor="typePasswordX-2">
                        Choose the vehicle
                      </label>

                      <Select
                        options={vehicles}
                        value={selectedVehicle}
                        onChange={(vehicle) => vehicleSelectHandler(vehicle)}
                      />
                    </div>
                  )}
                  {filteredServices && (
                    <div
                      data-mdb-input-init
                      className="form-outline mb-4 text-start"
                    >
                      <label className="form-label" htmlFor="typePasswordX-2">
                        Service Type
                      </label>

                      <Select
                        options={filteredServices}
                        value={selectedService}
                        placeholder="Select the service"
                        onChange={(service) => serviceSelectHandler(service)}
                      />
                    </div>
                  )}

                  {serviceStatus && (
                    <div
                      data-mdb-input-init
                      className="form-outline mb-4 text-start"
                    >
                      <label className="form-label" htmlFor="typePasswordX-3">
                        Service
                      </label>

                      <Select
                        options={filteredSubServices}
                        value={selectedSubService}
                        placeholder="Select the service"
                        onChange={(subService) =>
                          subServiceSelectHandler(subService)
                        }
                      />
                    </div>
                  )}
                  {wayTransferPrice !== "" && (
                    <h6 id="transferPrice">{wayTransferPrice}</h6>
                  )}

                  {cruiseTerminalsStatus && (
                    <div
                      data-mdb-input-init
                      className="form-outline mb-4 text-start"
                    >
                      <label className="form-label" htmlFor="typePasswordX-3">
                        Select Cruise Terminal
                      </label>

                      <Select
                        options={cruiseTerminals}
                        value={cruiseTerminal}
                        placeholder="Select the cruise Terminals"
                        onChange={(ct) => handleCruiseTerminal(ct)}
                      />
                    </div>
                  )}

                  {hoursStatus && (
                    <div
                      data-mdb-input-init
                      className="form-outline mb-4 text-start"
                    >
                      <label className="form-label" htmlFor="typePasswordX-3">
                        Select Hours
                      </label>

                      <Select
                        options={selectedHourlyData}
                        placeholder="Select the hours"
                        value={selectedHour}
                        onChange={(hours) => handleHoursData(hours)}
                      />
                    </div>
                  )}

                  {terminalStatus && (
                    <div
                      data-mdb-input-init
                      className="form-outline mb-4 text-start"
                    >
                      <label className="form-label" htmlFor="typePasswordX-3">
                        Select Terminal
                      </label>

                      <Select
                        options={terminalsData}
                        value={terminal}
                        placeholder="Select the Terminal"
                        onChange={(t) => {
                          setTerminal(t);
                          setDropoffAddress("");
                          setPickupAddress("");
                          if (
                            selectedSubService.subServiceCategory ===
                              "AIRPORT_DEP" ||
                            selectedSubService.subServiceCategory ===
                              "AIRPORT_DEP_ARR"
                          ) {
                            setDropoffAddress(`Airport ${t.value}`);
                          } else {
                            setPickupAddress(`Airport ${t.value}`);
                          }
                        }}
                      />
                    </div>
                  )}

                  <div
                    data-mdb-input-init
                    className="form-outline mb-4 text-start"
                  >
                    <label className="form-label" htmlFor="typePasswordX-2">
                      Pick-Up Date
                    </label>

                    <DatePicker
                      aria-label="Date"
                      type="date"
                      id="date"
                      format="dd/MM/y"
                      className="form-control form-control-lg"
                      //   placeholder="choose the vehicle"
                      value={date}
                      onChange={(d) => setDate(d)}
                    />
                  </div>

                  <div
                    data-mdb-input-init
                    className="form-outline mb-4 text-start"
                  >
                    <label className="form-label" htmlFor="typePasswordX-2">
                      Time
                    </label>

                    <TimePicker
                      aria-label="Time"
                      type="time"
                      id="time"
                      className="form-control form-control-lg"
                      //   placeholder="choose the vehicle"
                      value={time}
                      onChange={(t) => {
                        setTime(t);
                        let hour = null;

                        if (selectedHour) {
                          hour = selectedHour.hours;
                        }

                        handleMidnightSurcharge(selectedVehicle, t, hour);
                      }}
                    />
                  </div>

                  <div
                    data-mdb-input-init
                    className="form-outline mb-4 text-start"
                  >
                    <label className="form-label" htmlFor="typePasswordX-2">
                      Pick-up Address
                    </label>

                    <input
                      type="text"
                      id="pickupAddress"
                      className="form-control form-control-lg"
                      placeholder="Pick-up address"
                      value={pickupAddress}
                      onChange={(e) => setPickupAddress(e.target.value)}
                    />
                  </div>

                  <PickupDynamicForm
                    purpose="pick-up"
                    pickUpTrack={pickUpTrack}
                    ref={triggerAfterVehicleChangePURef}
                    additionalStopPrice={additionalStopPrice}
                  />

                  <div
                    data-mdb-input-init
                    className="form-outline mb-4 text-start"
                  >
                    <label className="form-label" htmlFor="typePasswordX-2">
                      Drop-off Address
                    </label>

                    <input
                      type="text"
                      id="dropoffAddress"
                      className="form-control form-control-lg"
                      placeholder="Drop-off address"
                      value={dropoffAddress}
                      onChange={(e) => setDropoffAddress(e.target.value)}
                    />
                  </div>

                  <DropoffDynamicForm
                    purpose="pick-up"
                    dropOffTrack={dropOffTrack}
                    ref={triggerAfterVehicleChangeDORef}
                    additionalStopPrice={additionalStopPrice}
                  />

                  <div
                    data-mdb-input-init
                    className="form-outline mb-4 text-start"
                  >
                    <label className="form-label" htmlFor="typePasswordX-2">
                      No of pax
                    </label>

                    <input
                      type="Number"
                      id="noOfPax"
                      className="form-control form-control-lg"
                      placeholder="0"
                      value={noOfPax}
                      onChange={(e) => setNoOfPax(e.target.value)}
                    />
                  </div>

                  <div
                    data-mdb-input-init
                    className="form-outline mb-4 text-start"
                  >
                    <label className="form-label" htmlFor="typePasswordX-2">
                      No of Luggages
                    </label>

                    <input
                      type="Number"
                      id="noOfLugg"
                      className="form-control form-control-lg"
                      placeholder="0"
                      value={noOfLuggage}
                      onChange={(e) => setNoOfLuggage(e.target.value)}
                    />
                  </div>

                  <div
                    data-mdb-input-init
                    className="form-outline mb-4 text-start"
                  >
                    <label className="form-label" htmlFor="typePasswordX-2">
                      Name
                    </label>

                    <input
                      type="text"
                      id="name"
                      className="form-control form-control-lg"
                      placeholder="Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>

                  <div
                    data-mdb-input-init
                    className="form-outline mb-4 text-start"
                  >
                    <label className="form-label" htmlFor="typePasswordX-2">
                      Email
                    </label>

                    <input
                      type="text"
                      id="email-2"
                      className="form-control form-control-lg"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  <div
                    data-mdb-input-init
                    className="form-outline mb-4 text-start"
                  >
                    <label className="form-label" htmlFor="typePasswordX-2">
                      Phone Number
                    </label>

                    <input
                      type="text"
                      id="phoneNumber"
                      className="form-control form-control-lg"
                      placeholder=""
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </div>

                  <div
                    data-mdb-input-init
                    className="form-outline mb-4 text-start"
                  >
                    <label className="form-label" htmlFor="typePasswordX-2">
                      Add your message
                    </label>

                    <textarea
                      type="text"
                      id="message"
                      className="form-control form-control-lg"
                      placeholder="Add your message"
                      rows="3"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    ></textarea>
                  </div>

                  <div
                    data-mdb-input-init
                    className="form-outline mb-4 text-start"
                  >
                    <label className="form-label" htmlFor="paymentmethod-2">
                      Payment Mode
                    </label>

                    <Select
                      options={paymentMethods}
                      placeholder="Select the Payment method"
                      value={selectedPaymentMethod}
                      onChange={(paymentMethod) => {
                        setSelectedPaymentMethod(paymentMethod);
                        handleSelectedPaymentMethod(paymentMethod);
                      }}
                    />
                  </div>

                  {/* ADDITIONAL CHARGES CHECK BOXES */}

                  <div className="form-check-outline mb-2 text-start ps-4">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="defaultCheck2"
                      checked={tuasSouthBoulevard}
                      onChange={ async (e) => {
                        let r = 0;
                        if (e.target.checked) {
                          r = 20;
                          setTuasSouthBoulevard(true);
                        } else {
                          r = 0;
                          setTuasSouthBoulevard(false);
                        }
                        await dispatch(
                          modifyTotalPriceAction(
                            {
                              ...fullPriceDetails,
                              priceDetails: {
                                ...fullPriceDetails.priceDetails,
                                tuasSouthBoulevard: r,
                              },
                            },
                            fullPriceDetails.paymentMethod
                          )
                        );
                      }}
                    />
                    <label className="form-check-label" htmlFor="defaultCheck2">
                      Tuas South Boulevard, Neo Tiew Road , Lim Chu kang ,
                      Kranji Additional $20
                    </label>
                  </div>

                  <p>
                    Any Additional Pick-up/Drop-off Out Of The Designated Route
                    Will Be Charged As Two-Way Trip
                  </p>

                  {fullPriceDetails && (
                    <>

                    {
                      fullPriceDetails.priceDetails.midnightSurcharge !==0 && (
                        <div className="form-check-outline mb-2 text-start ps-4">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          disabled={true}
                          id="midnightSurcharge"
                          checked={midnightSurcharge}
                          onChange={async (e) => {
                            let r = 0;
                            if (e.target.checked) {
                              r = 10;
                              setMidnightSurcharge(true);
                            } else {
                              r = 0;
                              setMidnightSurcharge(false);
                            }
                            await dispatch(
                              modifyTotalPriceAction(
                                {
                                  ...fullPriceDetails,
                                  priceDetails: {
                                    ...fullPriceDetails.priceDetails,
                                    midnightSurcharge: r,
                                  },
                                },
                                fullPriceDetails.paymentMethod
                              )
                            );
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck1"
                        >
                          Midnight Surcharge 23:30Hrs To 06:30Hrs Additional $
                          {fullPriceDetails.priceDetails.midnightSurcharge}
                        </label>
                      </div>
                      )
                    }
                      

                      {additionalPickupAddressData.additionalPickupCharges !==
                        0 && (
                        <div className="form-check-outline mb-2 text-start ps-4">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="defaultCheck3"
                            disabled={true}
                            checked={true}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="defaultCheck3"
                          >
                            Additional Pick-Up Charges $
                            {
                              additionalPickupAddressData.additionalPickupCharges
                            }
                          </label>
                        </div>
                      )}

                      {additionalDropOffAddressData.additionalDropOffCharges !==
                        0 && (
                        <div className="form-check-outline mb-2 text-start ps-4">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="defaultCheck3"
                            disabled={true}
                            checked={true}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="defaultCheck3"
                          >
                            Additional Drop-Off Charges $
                            {
                              additionalDropOffAddressData.additionalDropOffCharges
                            }
                          </label>
                        </div>
                      )}

                      {/* <div className="form-check-outline mb-2 text-start ps-4">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="defaultCheck3"
                          checked={additionalStopAlongtheWay}
                          onChange={(e) => {
                            let r = 0;
                            if (e.target.checked) {
                              r = 10;
                              setAdditionalStopAlongtheWay(true);
                            } else {
                              r = 0;
                              setAdditionalStopAlongtheWay(false);
                            }
                            dispatch(
                              modifyTotalPriceAction(
                                {
                                  ...fullPriceDetails,
                                  priceDetails: {
                                    ...fullPriceDetails.priceDetails,
                                    additionalStopAlongtheWay: r,
                                  },
                                },
                                fullPriceDetails.paymentMethod
                              )
                            );
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck3"
                        >
                          Additional Stop Along The Way (Below 2Km) $10
                        </label>
                      </div> */}

                      {/* <div className="form-check-outline mb-2 text-start ps-4">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="defaultCheck4"
                          checked={additionalStopOutoftheWay}
                          onChange={(e) => {
                            let r = 0;
                            if (e.target.checked) {
                              r = 20;
                              setAdditionalStopOutoftheWay(true);
                            } else {
                              r = 0;
                              setAdditionalStopOutoftheWay(false);
                            }
                            dispatch(
                              modifyTotalPriceAction(
                                {
                                  ...fullPriceDetails,
                                  priceDetails: {
                                    ...fullPriceDetails.priceDetails,
                                    additionalStopOutoftheWay: r,
                                  },
                                },
                                fullPriceDetails.paymentMethod
                              )
                            );
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck4"
                        >
                          Additional Stop Out Of The Way (Above 2Km) $20
                        </label>
                      </div> */}

                      <img
                        src="./images/paymeth/paymeth.png"
                        class="img-fluid"
                        alt="Responsive image"
                      ></img>
                      {termAndCondText && (
                        <div className="form-group form-check text-start pb-3 pt-3">
                          <label
                            className="form-check-label"
                            for="exampleCheck1"
                          >
                            {termAndCondText}
                          </label>
                        </div>
                      )}

                      <div className="form-group form-check text-start pb-3 pt-3 ps-4">
                        <input
                          type="checkbox"
                          onClick={(e) => {
                            console.log("event :", e.target.checked);
                            setSubmitBtnDisableState(!e.target.checked);
                          }}
                          className="form-check-input"
                          id="exampleCheck1"
                        />
                        <label className="form-check-label" for="exampleCheck1">
                          I accept all the terms & conditions
                        </label>
                      </div>

                      <button
                        data-mdb-button-init
                        data-mdb-ripple-init
                        className="btn btn-primary btn-lg btn-block mb-4"
                        type="submit"
                        id="submitBtn"
                        disabled={submitBtnDisableState}
                        onClick={(e) => submitHandler(e)}
                      >
                        {fullPriceDetails.totalPrice !== 0 &&
                          `$${fullPriceDetails.totalPrice.toFixed(2)} `}
                        Book Now
                      </button>
                    </>
                  )}

                  <hr className="my-4" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
