import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';

const PickupDynamicForm = forwardRef((props, ref) => {
  const [fields, setFields] = useState([]);

  // Use `useImperativeHandle` to expose functions
  useImperativeHandle(ref, () => ({
    triggerAfterVehicleChangePU,
}));

const triggerAfterVehicleChangePU = () => {
  if(fields.length === 0){
        return
    }
    // props.pickUpTrack(fields);
    setFields([])

  console.log('triggerAfterVehicleChangePU function called!');
};
  
  const addField = () => {
    setFields([...fields, { id: Date.now(), value: '' }]);
  };

  const deleteField = (id) => {
    setFields(fields.filter((field) => field.id !== id));
  };

  const handleChange = (id, newValue) => {
    setFields(
      fields.map((field) =>
        field.id === id ? { ...field, value: newValue } : field
      )
    );
  };

  useEffect(() => {
    // if(fields.length === 0){
    //     return
    // }
    props.pickUpTrack(fields);
  }, [fields])

  return (
    <div className="container  pb-3">
      <form>
        {fields.map((field) => (
          <div className="form-row d-flex justify-content-between mb-2" key={field.id}>
            
            <div className="col">
              <input
                type="text"
                className="form-control"
                value={field.value}
                onChange={(e) => handleChange(field.id, e.target.value)}
                placeholder="Please Add Additional Pick-Up Address"
              />
            </div>



            <div className="col-auto">
              <button
                type="button"
                className="btn btn-danger ml-2"
                onClick={() => deleteField(field.id)}
              >
                <i className="fas fa-trash"></i>
              </button>
            </div>


          </div>
        ))}



      </form>
      <button
        type="button"
        className="btn btn-sm btn-secondary mt-3"
        onClick={addField}
      >
        <i className="fas fa-plus"></i> Add more pick-up points @ ${props.additionalStopPrice}
      </button>
    </div>
  );
});

export default PickupDynamicForm;
