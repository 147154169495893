import React, { useEffect } from "react";
import axios from 'axios';

const PayPalButton = ({ amount }) => {
  useEffect(() => {
    // Load the PayPal script dynamically
    const script = document.createElement("script");
    script.src = `https://www.paypal.com/sdk/js?client-id=ATuNzYq4D6-56Tw5fdV7AGiDwlDYSFZYCCK7e0M8rOBQpzmmhJ4bP5xFIPuGicMiTFUZsWjGjxpGzTeT&components=buttons`;
    script.onload = () => {
      // Call PayPal Buttons
      window.paypal.Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: amount,
                },
              },
            ],
          });
        },
        onApprove: (data, actions) => {
          return actions.order.capture().then((details) => {
            // Send the transaction details to the backend for further processing
            const { id, payer } = details;
            console.log("Details : ", details);
            console.log("data with order id : ", data)


            // fetch("http://localhost:8000/api/v1/paypal/orders/payment", {
            //   mode: 'no-cors',
            //   method: "POST",
            //   headers: {
            //     "Content-Type": "application/json",
            //   },
            //   body: JSON.stringify({
            //     // payerID: payer.payer_id,
            //     orderID: data.orderID,
            //     paymentID: id,
            //   }),
            // })

            axios('http://localhost:8000/api/v1/paypal/orders/payment',{
              method: 'POST',
              data : {
                // payerID: payer.payer_id,
                orderID: data.orderID,
                paymentID: id,
              },
              headers: {
               // 'Authorization': `bearer ${token}`,
               'Content-Type': 'application/json'
             },
             mode: 'no-cors'
           })
            
            .then((response) => {
              console.log("Payment success", response)
                alert("Payment completed successfully");
            })
            .catch((error) => {
              console.error("Payment failed", error);
              alert("Payment failed");              
            });
              
          });
        },
        onError: (err) => {
          console.error("Error during payment", err);
          alert("An error occurred. Please try again.");
        },
      }).render("#paypal-button-container");
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [amount]);

  return <div id="paypal-button-container">

  </div>;
};

export default PayPalButton;
