import React from "react";
// import "bootstrap/dist/css/bootstrap.min.css";

const TermsAndConditions = () => {
  return (
    <div style={{ backgroundColor: "#ffffff", color: "black", padding: "20px" }}>
      <div className="container">
        <h1 className="text-center" style={{ color: "#091057" }}>Terms and Conditions</h1>
        <div className="card mt-4" style={{ backgroundColor: "#C6CEE0", color: "#091057" }}>
          <div className="card-body">
            <ol>
              <li>Advance Booking: We recommend booking in advance to minimize waiting time.</li>
              <li>
                Vehicle Assignment: The fastest available vehicle will be assigned within 10 to 20 minutes.
              </li>
              <li>
                Last-Minute Bookings: Estimated waiting time for last-minute bookings is 15-30 minutes,
                depending on traffic and vehicle availability.
              </li>
              <li>
                Early Morning Bookings: For rides between 11:30 PM to 06:30 AM, please book by 07:00 PM
                the day before to secure your spot.
              </li>
              <li>
                Additional Charges: Carpark, Electronic Road Pricing (ERP), and other related charges incurred
                during the trip will be invoiced at the conclusion of the journey.
              </li>
              <li>Prepayment: Mandatory for all bookings made on behalf of guests.</li>
              <li>
                Midnight Surcharge: For vehicles with 6, 7, 9, or 13 seats, a midnight surcharge of $10 applies between 23:30 and 06:30, while for 23-seater and 40-seater vehicles, the surcharge during the same hours is $30.
              </li>
              <li>
                Hourly Transfers: A midnight surcharge of $10 per hour will be applied for hourly transfers
                between 11:30 PM and 06:30 AM.
              </li>
              <li>
                Waiting Time: A complimentary waiting time of 10 minutes is provided for all transfers.
              </li>
              <li>
                Event Surcharge: Fares are subject to a 40% surcharge during events such as the Formula 1 race,
                New Year's Eve, Airshow, and other major holidays in Singapore. Notification for reconfirmation
                will be provided.
              </li>
              <li>
                Vehicle Availability: If no vehicle is available for your booking, we will arrange the next
                higher capacity vehicle at no extra cost. Please indicate any specific vehicle preferences in
                the booking page's message section.
              </li>
              <li>
                Payment Methods: Accepted payment methods include Credit Card, Bank Transfer, PayNow, and Cash.
              </li>
              <li>
                No-Shows: Corporate and company booking passenger no-shows on the day of pick-up will incur a
                100% cancellation fee.
              </li>
              <li>
                Service Fee: A 5% service fee applies to any booking cancellations made less than 4 hours before
                the scheduled pick-up time.
              </li>
              <li>
                Cancellations or Amendments: Should be notified at least 12 hours before the scheduled pick-up
                time.
              </li>
              <li>Service Hours: Our standard service hours are 5 AM to 12 AM.</li>
              <li>Additional Fees: Services outside of standard hours will incur additional fees.</li>
              <li>
                Tolls and Parking Fees: Not included in the initial quote and will be charged separately.
              </li>
              <li>
                Damages: Any damages to the vehicle or interior caused by passengers will be charged.
              </li>
              <li>
                Liability: We are not liable for any delays or cancellations caused by unforeseen circumstances,
                such as traffic, weather, or mechanical issues.
              </li>
            </ol>
          </div>
        </div>

        <h2 className="text-center mt-5" style={{ color: "#091057" }}>Passenger Capacity and Luggage</h2>
        <div className="table-responsive">
          <table className="table table-bordered" style={{ color: "#091057" }}>
            <thead>
              <tr style={{ backgroundColor: "#091057", color: "#fff" }}>
                <th>Vehicle</th>
                <th>Passenger Capacity</th>
                <th>Luggage Capacity</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>6-Seater MaxiCab Standard</td>
                <td>4 Pax</td>
                <td>4 Mid-Size Luggage</td>
              </tr>
              <tr>
                <td>6-Seater MaxiCab Premium Car</td>
                <td>5 Pax</td>
                <td>5 Cabin Sized Luggage</td>
              </tr>
              <tr>
                <td>7-Seater MaxiCab</td>
                <td>7 Pax</td>
                <td>7 Mid-Sized Luggage</td>
              </tr>
              <tr>
                <td>9-Seater Minibus</td>
                <td>9 Pax</td>
                <td>9 Mid-Sized Luggage</td>
              </tr>
              <tr>
                <td>13-Seater Minibus</td>
                <td>13 Pax</td>
                <td>Hand Carry Bags</td>
              </tr>
              <tr>
                <td>23-Seater Coach</td>
                <td>23 Pax</td>
                <td>4 Mid-Sized Luggage</td>
              </tr>
              <tr>
                <td>40-Seater Coach</td>
                <td>40 Pax</td>
                <td>25 Mid-Sized Luggage</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
