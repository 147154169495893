import React, { useEffect, useState } from "react";
import axios from "axios";

const PaymentHitpay = () => {
  // const [amount, setAmount] = useState("1");

  let bookingData = JSON.parse(sessionStorage.getItem("bookingData"));

  const [heading, setHeading] = useState("");

  const [btnDisableState, setBtnDisableState] = useState(false);
  
  
  const amount = bookingData.rate.fullAmount;
  const paymentMode = bookingData.paymentDetails.paymentMode

  useEffect(() => {
    if(paymentMode === "CREDIT_CARD_PAYMENT") {
      setHeading("Payment through Credit/Debit cards")
    }else{
      setHeading("Payment through Paynow QR")
    }
  }, [])

  const handlePayment = async (e) => {

    setBtnDisableState(true);
    e.preventDefault();

    try {
      const response = await axios.post("/api/v1/payment/hitpay", {
        amount,
        // mode: 'no-cors',
        paymentMode
      });
      console.log("phase 1 response DATA : ", response.data);

      window.location.href = response.data.redirect_url; // Redirect to HitPay payment page
    } catch (error) {
      console.log("Payment Error:", error);
      setBtnDisableState(false);
    }
  };

  return (
  
    <>
     {/* <MetaData title={`Login`}/> */}
     <section className="vh-100" style={{ backgroundColor: "#508bfc" }}>
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
              <div
                className="card shadow-2-strong"
                style={{ borderRadius: "1rem" }}
              >
                <div className="card-body p-5 text-center">
                  <h3 className="mb-5">{heading}</h3>
                  {/* <h6 className={textColor}>{resultText}</h6> */}

                  <hr className="my-4" />
                  
                    <button
                    data-mdb-button-init
                    data-mdb-ripple-init
                    className="btn btn-primary btn-lg "
                    type="button"
                    id="submit_btn"
                    disabled={btnDisableState}
                    onClick={(e) => handlePayment(e)}
                  >
                   Pay ${amount.toFixed(2)}
                  </button>
                  

                
                    
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>        



  );
};

export default PaymentHitpay;
