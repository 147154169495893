import React, { useState } from "react";
import MetaData from "../layouts/MetaData";
import { useEffect } from "react";
// import { getProducts } from "../actions/productsActions";
import { useDispatch, useSelector } from "react-redux";
// import { getVehicles } from "../actions/vehicleActions";
// import Hero from './hero/Hero'
import VehicleList from "../contents/VehicleList";
import { useNavigate } from "react-router-dom";
import StarRate from "../ratings/StarRate";
import { getRatings } from "../../actions/ratingsAction";
import RatingCard from "../ratings/RatingCard";
import { clearRatingCreated, clearRatingError } from "../../slices/ratingSlice";
import { toast } from "react-toastify";

export default function ShowRatingHome() {
     const navigate = useNavigate();
  const dispatch = useDispatch();

  const [reload, setReload] = useState(true)

//   const { vehicles } = useSelector((state) => state.vehicleState);
//   const { loading, userLoading, error, isAuthenticated, user } = useSelector(
//     (state) => state.authState
//   );
  const reloadFunc = () => {
    setReload(!reload);
    dispatch(getRatings)
  }
  const { loading, ratings, result, error: ratingError  } = useSelector(
    (state) => state.ratingsState
  );

  useEffect(() => {
    console.log("==> _id check Show rating home", ratings)
    if(ratings.length === 0) {
        console.log("ratings :", ratings);
        dispatch(getRatings)
    }

      if (ratingError) {
        toast(ratingError, {
          position: "bottom-center",
          type: "error",
          onOpen: () => {
            dispatch(clearRatingError);
            
          },
        });
        return;
      }

    
    
  }, [
    ratings,
    loading,
    result,
    reload
  ]);

  return (
    
    <>
    <h2 className="text-center">Ratings</h2>        

      {
        ratings.length !== 0 &&(
            ratings.map((rating) => (
                <RatingCard rating={rating} reloadFunc={reloadFunc} purpose={{page: "HOME"}}/>
            ))
        )
      }

<div className="h-100 d-flex align-items-center justify-content-center mb-lg-5 mb-2">
      <button type="button" class="btn btn-primary px-5" style={{color:"#fff", backgroundColor:"#091057"}} 
      onClick={(e)=> {navigate('/ratings')}}
      >Add your Rating</button>
      </div>

      
       
      <div className="h-100 d-flex align-items-center justify-content-center mb-lg-5 mb-2">
      
      <a href="https://g.page/r/CXfx52l9tjcAEAI/review" target="_blank"
      style={{color:"#fff", backgroundColor:"#DB4437"}}
      class="btn btn-primary px-5">Add Google Rating</a>
      </div>
    </>
  );
}
