import React, { useState } from "react";
import MetaData from "../layouts/MetaData";
import { useEffect } from "react";
// import { getProducts } from "../actions/productsActions";
import { useDispatch, useSelector } from "react-redux";
// import { getVehicles } from "../actions/vehicleActions";
// import Hero from './hero/Hero'
import VehicleList from "../contents/VehicleList";
import { useNavigate } from "react-router-dom";
import StarRate from "../ratings/StarRate";
import { getRatings } from "../../actions/ratingsAction";
import RatingCard from "../ratings/RatingCard";
import { clearRatingCreated, clearRatingError } from "../../slices/ratingSlice";
import { toast } from "react-toastify";

export default function Ratings() {
     const navigate = useNavigate();
  const dispatch = useDispatch();

  const [reload, setReload] = useState(true)

//   const { vehicles } = useSelector((state) => state.vehicleState);
//   const { loading, userLoading, error, isAuthenticated, user } = useSelector(
//     (state) => state.authState
//   );
  const reloadFunc = () => {
    setReload(!reload);
    dispatch(getRatings)
  }
  const { loading, ratings, result, error: ratingError  } = useSelector(
    (state) => state.ratingsState
  );

  useEffect(() => {
    console.log("check ratings [] ", ratings)
    if(ratings.length === 0) {
        console.log("ratings :", ratings);
        dispatch(getRatings)
    }

      if (ratingError) {
        toast(ratingError, {
          position: "bottom-center",
          type: "error",
          onOpen: () => {
            dispatch(clearRatingError);
            
          },
        });
        return;
      }

    
    
  }, [
    ratings,
    loading,
    result,
    reload
  ]);

  return (
    
    <>
      <MetaData title={"Maxicabride - Ratings"} />
        
      <StarRate reloadFunc={reloadFunc}/>

      {
        ratings.length !== 0 &&(
            ratings.map((rating) => (
                <RatingCard rating={rating} reloadFunc={reloadFunc} purpose={{page: "RATINGS"}}/>
            ))
        )
      }
       
    </>
  );
}
