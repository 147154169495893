import React from "react";
import { Link, useNavigate } from "react-router-dom";

export default function Home() {

  return (
    
    <>
     
        <header className="masthead bg-primary text-white text-center" style={{backgroundColor: "#C6CEE0 !important"}} >
            <div className="container d-flex align-items-center flex-column">
                {/* <!-- Masthead Avatar Image--> */}
                <img className="masthead-avatar mb-5" src="./images/maxicabride-logo.png" alt="logo" />
                {/* <!-- Masthead Heading--> */}
                {/* <h1 className="masthead-heading text-uppercase mb-0">MAXICABRIDE</h1> */}
                {/* <!-- Icon Divider--> */}
                <div className="divider-custom divider-light">
                    <div className="divider-custom-line"></div>
                    <div className="divider-custom-icon">
                    <img src="./images/blue-star.svg" className="img-fluid hero-star" alt="Responsive image"/>
                      
                      </div>

                    <div className="divider-custom-line"></div>
                </div>
                <Link to='/booknow' type="button" class="btn btn-primary btn-lg ">Book Now</Link>
                {/* <!-- Masthead Subheading--> */}
                <p className="masthead-subheading font-weight-light mb-0 pt-4">Download Our Mobile App & Get Discount</p>
            </div>
        </header>


    </>
  );
}
