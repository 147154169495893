import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { clearOrderCreated } from "../../../slices/ordersSlice";
import { createOrder } from "../../../actions/orderActions";

const HitpaySuccess = () => {
  let m=0;
  let bookingData = JSON.parse(sessionStorage.getItem("bookingData"));

  console.log("bookingData  : ", bookingData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [heading, setHeading] = useState("Please wait ...");
  const [orderCreated, setOrderCreated] = useState(false);
  const [btnDisableState, setBtnDisableState] = useState(true);

  
  let {  reference } = useParams();
  //status=completed

  let status = (new URLSearchParams(window.location.search)).get("status")

  console.log(' : status == ', status)

  const { isOrderCreated, order, error:orderError } = useSelector((state) => state.orderState);

  useEffect(() => {
    console.log("INSIDE payment success useEffect() ");
    console.log("status ", status);
    console.log("reference ", reference);


    if (isOrderCreated) {
    console.log("INSIDE Yes isOrderCreated ")

      // setHeading("Done!");
      // setResultText("Your payment is successful");
      
      toast("Booking successful , You will receive email soon", {
        position: "bottom-center",
        type: "success",
        onOpen: () => {
          setTimeout(() => {
            
            

            // document.getElementById("submitBtn").disabled = false;

          }, "5000");

          dispatch(clearOrderCreated());
        },
      });
      return;
    }

    if(status==='completed' && bookingData !== null ) {
      setOrderCreated(true);
    console.log("INSIDE Create order ")

      setHeading("Payment Successful");

      dispatch(
        createOrder({
          ...bookingData,
          paymentDetails: {
            paymentMode: "PAYNOW_QR",
            paymentStatus: "PAID",
            paidAt: new Date(),
            paymentTxnId: reference,
          },
        })
      );
      
      sessionStorage.clear();
      setBtnDisableState(false)
    }
  }, [dispatch]);



  return (
    <>
    <section className="vh-100" style={{ backgroundColor: "#508bfc" }}>
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
              <div
                className="card shadow-2-strong"
                style={{ borderRadius: "1rem" }}
              >
                <div className="card-body p-5 text-center">
                  <h3 className="mb-3">{heading}</h3>

                  {isOrderCreated && (
                    <>
                    <p>Your order has been successfully placed!, you will receive our email confirmation</p>
                    </>
                  )}

                  {orderError && (
                    <>
                    <p>Oops! Something went wrong, Please try again</p>
                    </>
                  )}

                  {/* <h6 className={textColor}>{resultText}</h6> */}

                  <hr className="my-4" />
                  
                    <button
                    data-mdb-button-init
                    data-mdb-ripple-init
                    className="btn btn-primary btn-lg "
                    type="button"
                    id="submit_btn"
                    disabled={btnDisableState}
                    onClick={(e) => navigate('/')}
                  >
                   Go to home
                  </button>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HitpaySuccess;
