// const { createSlice } = require("@reduxjs/toolkit");
import { createSlice } from "@reduxjs/toolkit";


const ratingsSlice = createSlice({
    name : "ratings",
    initialState : {
        loading : false,
        ratings : [],
        isRatingCreated : false,
    },
    reducers : {
        getRatingsRequest(state, action) {
            return {
                ...state,
                loading : true,
                
            }
        },
        getRatingsSuccess(state, action) {
            return {
                ...state,
                loading : false,
                ratings : action.payload.ratings
            }
        },
        getRatingsFail(state, action) {
            return {
                ...state,
                loading : false,
                error : action.payload,
                ratings : []
            }
        },
        createRatingsRequest(state, action) {
            return {
                ...state,
                loading : true,
                
            }
        },
        createRatingsSuccess(state, action) {
            return {
                ...state,
                loading : false,
                rating : action.payload.rating,
                isRatingCreated: true
                
            }
        },
        createRatingsFail(state, action) {
            return {
                ...state,
                loading : false,
                error : action.payload,
                
            }
        },
        updateRatingsRequest(state, action) {
            return {
                ...state,
                loading : true,
                
            }
        },
        updateRatingsSuccess(state, action) {
            return {
                ...state,
                loading : false,
                rating : action.payload.rating,
                
            }
        },
        updateRatingsFail(state, action) {
            return {
                ...state,
                loading : false,
                error : action.payload,
                
            }
        },
        deleteRatingsRequest(state, action) {
            return {
                ...state,
                loading : true,
                
            }
        },
        deleteRatingsSuccess(state, action) {
            return {
                ...state,
                loading : false,
                result : action.payload.message,
                isRatingDeleted : true
                
            }
        },
        deleteRatingsFail(state, action) {
            return {
                ...state,
                loading : false,
                error : action.payload,
                isRatingDeleted : false
            }
        },
        clearRatingCreated(state, action) {
            return {
                ...state,
                isRatingCreated : false

            }
        },

        clearRatingError(state, action) {
            return {
                ...state,
                isOrderCreated : false,
                error : null

            }
        },
        clearRatingDeleted(state, action) {
            return {
                ...state,
                isRatingDeleted : false

            }
        },

    }
})

const {actions, reducer} = ratingsSlice;

export const {
    getRatingsSuccess, getRatingsRequest, getRatingsFail,
    createRatingsRequest, createRatingsSuccess, createRatingsFail,
    updateRatingsSuccess, updateRatingsRequest, updateRatingsFail,
    deleteRatingsRequest, deleteRatingsSuccess, deleteRatingsFail,
    clearRatingCreated, clearRatingError, clearRatingDeleted
} = actions;

export default reducer;
