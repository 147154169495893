import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Rating from "react-rating";
import { useDispatch, useSelector } from "react-redux";
import { clearAuthError } from "../../actions/userActions";
import { toast } from "react-toastify";
import { deleteRating } from "../../actions/ratingsAction";
import { clearRatingDeleted } from "../../slices/ratingSlice";

export default function RatingCard(props) {

  const [ratingNumber, setRatingNumber] = useState(0);
  const [commentText, setCommentText] = useState("");
  const [sameUser, setSameUser] = useState(false);
  const [deleteBtnState, setDeleteBtnState] = useState(false);
  

  
  const { loading, userLoading, error, isAuthenticated, user } = useSelector(
    (state) => state.authState
  );


  const { loading : ratingLoading, ratings, result, isRatingCreated, error : ratingError, isRatingDeleted  } = useSelector(
    (state) => state.ratingsState
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  useEffect(() => {
    console.log("==> _id check Rating card", ratings)
    if (isRatingDeleted) {
      console.log("Rating deleted");

      toast("Rating deleted", {
        position: "bottom-center",
        type: "error",
        onOpen: () => {
          
          // setTimeout(() => {
          //   setDeleteBtnState(false)
          //   props.reloadFunc()
          // }, "3000");
          dispatch(clearRatingDeleted());
            setDeleteBtnState(false)
            props.reloadFunc()
        },
      });
      return;
    }
  }, [
    isRatingDeleted
  ])

  const submitEditOrDelete = (e, process) => {
    e.preventDefault();
    console.log("e ", e.target.id, " process ", process)

    if(process === 'EDIT') {
        console.log("edit the data");
    }else {
      setDeleteBtnState(true)
        dispatch(deleteRating(e.target.id))  
    }

  }

 console.log("props : ", props);
 console.log("user : ", user);

 if(props.purpose.page === "RATINGS"){
  return (
    <>
      {/* <div className="h-100 d-flex align-items-center justify-content-center">
        <div className="form-group rating-comment">
          <label for="exampleFormControlTextarea1" style={{color:"#091057"}} > Add your comment </label>
          <textarea
          onChange={(e) => {setCommentText(e.target.value)}}
            className="form-control"
            id="exampleFormControlTextarea1"
            rows="3"
          ></textarea>
        </div>
      </div> */}

      {
        props.rating.length !== 0 && (
          <div className="h-100 d-flex align-items-center justify-content-center" key={props.rating._id}>

      <div className="card rating-comment" >

      <div className="card-header rating-card-header">
    
      <p className="ratings-card-name">{props.rating.userName}</p>

    <Rating

        placeholderRating={props.rating.rating}

        placeholderSymbol={
        <img src="./images/ratings/blue-star.svg" 
        className="icon ratings-card-star" />
        }

        onChange={(e)=> { setRatingNumber(e)}}

          emptySymbol={
            <img
              src="./images/ratings/blue-star-empty.svg"
              className="icon ratings-card-star"
            />
          }
          fullSymbol={
            <img
              src="./images/ratings/blue-star.svg"
              className="icon ratings-card-star"
            />
          }
          fractions={2}
        />
  </div>
  
  {/* <div className="h-100 d-flex align-items-center justify-content-center" > */}
  
  <div className="card-body ">
    {/* <h5 className="card-title"></h5> */}
    <p className="card-text">{props.rating.comment}</p>
    {
        user._id === props.rating.userId && (
          <>
            {/* <button type="button" className="btn btn-sm btn-primary me-4"  id={props.rating._id}
            onClick={(e)=> {submitEditOrDelete(e, 'EDIT')}}>edit</button> */}
            <button type="button" className="btn btn-sm  btn-danger " key={props.rating._id} id={props.rating._id}
            onClick={(e)=> {submitEditOrDelete(e, 'DELETE')}}
            disabled={deleteBtnState}
            >delete</button>

</>
        )
    }
    
  </div>

</div>
</div>
        )
      }
      

    </>

);
 }else {
  return (
    <>
      {/* <div className="h-100 d-flex align-items-center justify-content-center">
        <div className="form-group rating-comment">
          <label for="exampleFormControlTextarea1" style={{color:"#091057"}} > Add your comment </label>
          <textarea
          onChange={(e) => {setCommentText(e.target.value)}}
            className="form-control"
            id="exampleFormControlTextarea1"
            rows="3"
          ></textarea>
        </div>
      </div> */}

      {
        props.rating.length !== 0 && (
          <div className="h-100 d-flex align-items-center justify-content-center" key={props.rating._id}>

      <div className="card rating-comment" >

      <div className="card-header rating-card-header pe-4" >
    <p className="ratings-card-name">{props.rating.userName}</p>
    
    <Rating
        disable={true}
        placeholderRating={props.rating.rating}

        placeholderSymbol={
        <img src="./images/ratings/blue-star.svg" 
            
        className="icon ratings-card-star" />
        }



          emptySymbol={
            <img
              src="./images/ratings/blue-star-empty.svg"
              
              className="icon ratings-card-star"
            />
          }
          fullSymbol={
            <img
              src="./images/ratings/blue-star.svg"
              
              className="icon ratings-card-star"
            />
          }
          fractions={2}
        />
  </div>
  
  {/* <div className="h-100 d-flex align-items-center justify-content-center" > */}
  
  <div className="card-body ">
    {/* <h5 className="card-title"></h5> */}
    
    <p className="card-text">{props.rating.comment}</p>
    
  </div>

</div>
</div>
        )
      }
      

    </>

);
 }
  
}
