import axios from 'axios';
import { createRatingsFail, createRatingsRequest, createRatingsSuccess, 
    deleteRatingsFail, 
    deleteRatingsRequest, 
    deleteRatingsSuccess, 
    getRatingsFail, getRatingsRequest, getRatingsSuccess,
    updateRatingsFail,
    updateRatingsRequest,
    updateRatingsSuccess} from '../slices/ratingSlice';

// export const login = (email, password) => async (dispatch) => {
//     try {
//         dispatch(loginRequest());
//         const {data} = await axios.post(`/api/v1/login`, {email, password});
//         dispatch(loginSuccess(data));
//     } catch (error) {
//         dispatch(loginFail(error.response.data.message));
//     }
// }

// export const clearAuthError = dispatch => {
//     dispatch(clearError())
// }

export const createRating = (ratingData) => async (dispatch) => {
    try {
        dispatch(createRatingsRequest());
        const config = {
            headers : {
                'Content-type' : 'application/json'
            }
        }
        
        const {data} = await axios.post(`/api/v1/rating/new`, ratingData, config);

        dispatch(createRatingsSuccess(data));

    } catch (error) {
        dispatch(createRatingsFail(error.response.data.message));
    }
}


export const getRatings = async (dispatch) => {
    try {
        dispatch(getRatingsRequest());
        const {data} = await axios.get('/api/v1/ratings');
        dispatch(getRatingsSuccess(data));

    } catch (error) {
        dispatch(getRatingsFail(error.response.data.message));    
    }
}

// export const loadUser = async (dispatch) => {

//     try {
//         dispatch(loadUserRequest());
//         const {data} = await axios.get(`/api/v1/myprofile`);

//         dispatch(loadUserSuccess(data)); 
//     } catch (error) {
//         console.log("Error in loadUser 007 : ", error.response.data.message)
//         dispatch(loadUserFail(error.response.data.message));
        
//     }
// }


// export const logout = async (dispatch) => {

//     try {
//         await axios.get(`/api/v1/logout`);

//         dispatch(logoutSuccess()); 
//     } catch (error) {
//         dispatch(logoutFail(error.response.data.message));
        
//     }
// }


export const updateRating = (ratingData, ratingId) => async (dispatch) => {
    try {
        dispatch(updateRatingsRequest());
        const config = {
            headers : {
                'Content-type' : 'multipart/form-data'
            }
        }
        
        const {data} = await axios.put(`/api/v1/rating/${ratingId}`, ratingData, config);

        dispatch(updateRatingsSuccess(data));

    } catch (error) {
        dispatch(updateRatingsFail(error.response.data.message));
    }
}


export const deleteRating = (ratingId) => async (dispatch) => {
    try {
        dispatch(deleteRatingsRequest());
        
        const {data} = await axios.delete(`/api/v1/rating/${ratingId}`);

        dispatch(deleteRatingsSuccess(data));

    } catch (error) {
        dispatch(deleteRatingsFail(error.response.data.message));
    }
}

